import React from "react";
import {
  TextInput,
  TabbedForm,
  FormTab,
  ImageField,
  ImageInput,
  Create
} from "react-admin";
import { Typography } from "@material-ui/core";
import RichTextInputWithExtendedToolbar from "../shared/components/RichTextInputWithExtendedToolbar";
import Divider from "../shared/components/CustomDivider";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";
import TriviaToolbar from "./components/TriviaToolbar";

export const TriviaCreate = props => (
  <Create {...props}>
    <TabbedForm
      toolbar={<TriviaToolbar redirect="edit" />}
      validate={validateTrivia}
    >
      <FormTab label="Informacje">
        <Typography variant="h6">Czy wiesz, że...</Typography>
        <TextInput
          label="Krótki opis"
          multiline
          fullWidth
          source="shortDescription"
        />
        <RichTextInputWithExtendedToolbar
          label="Długi opis"
          source="longDescription"
        />
        <Divider />
        <IsPublicatedRadioButtonGroup />
      </FormTab>
      <FormTab label="Zdjęcie">
        <ImageInput source="images" label="Zdjęcie" accept="image/*">
          <ImageField source="path" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

const validateTrivia = values => {
  const errors = {};
  if (!values.shortDescription) {
    errors.shortDescription = ["Krótki opis jest wymagany"];
  } else {
    if (values.shortDescription.length > 150) {
      errors.shortDescription = [
        "Krótki opis może posiadać maksymalnie 150 znaków"
      ];
    }
  }
  if (!values.longDescription) {
    errors.longDescription = ["Długi opis jest wymagany"];
  } else {
    if (values.longDescription.length > 8000) {
      errors.longDescription = [
        "Długi opis może posiadać maksymalnie 8000 znaków"
      ];
    }
  }
  if (
    values.images &&
    values.images.rawFile &&
    values.images.rawFile.size > 20000000
  ) {
    errors.images = ["Zdjęcia mogą posiadać rozmiar maksymalnie 20mb."];
  }
  return errors;
};

import React, { useCallback } from "react";
import { useForm } from "react-final-form";
import { SaveButton } from "react-admin";
import FileToBase64Converter from "../../shared/components/FileToBase64Converter";

const TriviaSaveButton = ({ handleSubmitWithRedirect, redirect, ...props }) => {
  const form = useForm();

  const setupImages = () => {
    form.change("mainImagePath", null);
    let images = form.getFieldState("images").value;
    if (images) {
      let newImages = [images].filter(p => p.rawFile instanceof File);
      let formerImages = [images].filter(p => !(p.rawFile instanceof File));

      if (newImages && newImages.length > 0) {
        Promise.all(newImages.map(FileToBase64Converter))
          .then(base64Images =>
            base64Images.map(result => ({
              base64File: result.file64
            }))
          )
          .then(transformedNewImages => {
            if (transformedNewImages) {
              form.change("mainBase64File", transformedNewImages[0].base64File);
            }
            handleSubmitWithRedirect(redirect);
          });
      } else {
        if (
          formerImages &&
          formerImages.length > 0 &&
          formerImages[0] &&
          formerImages[0].length > 0
        ) {
          form.change("mainImagePath", formerImages[0][0].path);
          handleSubmitWithRedirect(redirect);
        } else {
          handleSubmitWithRedirect(redirect);
        }
      }
    } else {
      handleSubmitWithRedirect(redirect);
    }
  };

  const handleClick = useCallback(() => {
    setupImages();

    // eslint-disable-next-line
  }, [form]);

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      redirect={redirect}
    />
  );
};

export default TriviaSaveButton;

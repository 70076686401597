import React from "react";
import {
  TextInput,
  TabbedForm,
  FormTab,
  ImageField,
  ImageInput,
  Create
} from "react-admin";
import RichTextInputWithExtendedToolbar from "../shared/components/RichTextInputWithExtendedToolbar";
import Divider from "../shared/components/CustomDivider";
import IsPublicatedRadioButtonGroup from "../shared/components/IsPublicatedRadioButtonGroup";
import NewsToolbar from "./components/NewsToolbar";

export const NewsCreate = props => (
  <Create {...props}>
    <TabbedForm
      toolbar={<NewsToolbar redirect="edit" />}
      validate={validateNews}
    >
      <FormTab label="Informacje">
        <TextInput label="Tytuł" fullWidth source="title" />
        <TextInput
          label="Krótki opis"
          multiline
          fullWidth
          source="shortDescription"
        />
        <RichTextInputWithExtendedToolbar
          label="Długi opis"
          source="longDescription"
        />
        <Divider />
        <IsPublicatedRadioButtonGroup />
      </FormTab>
      <FormTab label="Zdjęcie">
        <ImageInput source="mainImage" label="Główne zdjęcie" accept="image/*">
          <ImageField source="path" />
        </ImageInput>
        <ImageInput
          source="images"
          label="Pozostałe zdjęcia (Uwaga: Pierwsze zdjęcie stanowić będzie okładkę albumu. W przypadku wgrywania wielu zdjęć jednocześnie ich kolejność będzie równoznaczna z kolejnością w albumie!)"
          accept="image/*"
          multiple
        >
          <ImageField source="path" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

const validateNews = async values => {
  const errors = {};
  if (!values.title) {
    errors.title = ["Tytuł jest wymagany"];
  } else {
    if (values.title.length > 100) {
      errors.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
    }
  }
  if (!values.shortDescription) {
    errors.shortDescription = ["Krótki opis jest wymagany"];
  } else {
    if (values.shortDescription.length > 300) {
      errors.shortDescription = [
        "Krótki opis może posiadać maksymalnie 300 znaków"
      ];
    }
  }
  if (!values.longDescription) {
    errors.longDescription = ["Długi opis jest wymagany"];
  } else {
    if (values.longDescription.length > 8000) {
      errors.longDescription = [
        "Długi opis może posiadać maksymalnie 8000 znaków"
      ];
    }
  }
  if (
    values.mainImage &&
    values.mainImage.rawFile &&
    values.mainImage.rawFile.size > 20000000
  ) {
    errors.mainImage = ["Zdjęcia mogą posiadać rozmiar maksymalnie 20mb."];
  }

  if (values.images) {
    await values.images.map(image => {
      if (image.rawFile && image.rawFile.size > 20000000) {
        return (errors.images = [
          "Zdjęcia mogą posiadać rozmiar maksymalnie 20mb."
        ]);
      }
      return true;
    });
  }
  return errors;
};
